import React from "react";
import styled from "styled-components";
import { ContentWrapper } from "../assets/styles/base";

import { breakpoints } from "../constants/breakpoints";

const GreyHeader = ({ title }) => {
    return (
        <Wrapper>
            <ContentWrapper hasMarginTop>
                <h1 className="no-margin">{title}</h1>
            </ContentWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    font-family: "Inter", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    line-height: 1.5;

    background: #f3f4f6;
    border: 1px solid #d1d5db;
    box-sizing: border-box;
    min-height: 130px;

    h1 {
        color: #374151;
        font-weight: 300;
        line-height: 1.5;
        margin: 0;
        max-width: 720px;
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        min-height: 250px;

        h1 {
            margin-left: 30px;
            margin-right: 30px;
        }
    }
`;

export default GreyHeader;
