import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "wasSindZinsen";

    const { question, answer, title, description } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${title} | miracl`} description={description} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <h3>Unterscheidung nach Zinssatz</h3>

                <p>
                    Die ersten beiden Begriffe, die du im Zusammenhang mit Zinsen unbedingt verstehen solltest, sind
                    nominaler Zinssatz und effektiver Zinssatz.
                </p>
                <ul>
                    <li>
                        <b>Nominaler/Soll Zinssatz: </b>
                        <p>
                            Unter dem nominalen beziehungsweise soll Zinssatz versteht man das Entgelt, das du für
                            deinen Kredit bezahlen musst. Man spricht in diesem Zusammenhang auch oft von den
                            Zinskosten.
                        </p>
                    </li>

                    <br />

                    <li>
                        <b>Effektiver Zinssatz: </b>
                        <p>
                            Anders als der nominale enthält der Effektivzins sämtliche Nebenkosten des Kredits. Also
                            neben den Zinskosten auch die Bearbeitungsgebühren, die Kosten für die Eintragung des
                            Pfandrechts in das Grundbuch, Schätzkosten, Kontoführungsgebühren sowie die Prämien der
                            obligatorischen Risikolebensversicherung. Der effektive Zinssatz ist somit auch der
                            aussagekräftigere Parameter, wenn du dich daran machst, Kreditangebote zu vergleichen.
                            Sofern die Variablen der jeweiligen Offerte (Laufzeit, Zinsbindung etc.) identisch sind.
                        </p>
                    </li>
                </ul>
                <p>
                    <b>
                        Hier gehts weiter zur Frage:
                        <br />
                        <a href={"/asdfsd"}>Welche Zinsmodelle habe ich zur Auswahl?</a>
                    </b>
                </p>
            </Article>

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData
                page={key}
                heading={question}
                description={answer}
                datePublished="2022-09-12"
                dateModified="2022-09-12"
            />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
