import React from "react";
import styled from "styled-components";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { ContentWrapper, GreyBg, H2 } from "../../assets/styles/base";
import { breakpoints } from "../../constants/breakpoints";
import TipsCard from "./TipsCard";

import "swiper/css";
import "swiper/css/pagination";
import { colors } from "../../constants/colors";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const TipsCarousel = ({ hasGreyBg = true, numberOfTips = 4, hasMarginTop, title, ignoreId }) => {
    let tipsArray = [];

    for (let id in TIPS_AND_TRICKS_LIST) {
        if (id !== ignoreId) tipsArray.push(TIPS_AND_TRICKS_LIST[id]);
    }

    const randomIndex = new Array(tipsArray.length > numberOfTips ? numberOfTips : tipsArray.length)
        .fill()
        .map((a, i) => (a = i))
        .sort(() => Math.random() - 0.5);
    let tipsToShow = [];

    const Content = (
        <StyledContentWrapper hasMarginTop={hasMarginTop}>
            <IntroWrapper>
                <H2>{title}</H2>
            </IntroWrapper>
            <CardsWrapper>
                <Swiper
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    loop={true}
                    spaceBetween={32}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="tipsSwiper"
                >
                    {randomIndex.map((index) => {
                        const tip = tipsArray[index];
                        if (tip !== ignoreId) tipsToShow.push(tip);
                    })}
                    {tipsToShow.map((tipData, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <TipsCard data={tipData} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </CardsWrapper>
        </StyledContentWrapper>
    );
    if (hasGreyBg) return <GreyBg style={{ marginBottom: "-50px" }}>{Content}</GreyBg>;
    return Content;
};

const StyledContentWrapper = styled(ContentWrapper)`
    font-family: "Inter", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    line-height: 1.5;
    color: #4b5563;
    margin-bottom: -40px;
    max-width: none;

    padding-bottom: 80px;
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
`;

const IntroWrapper = styled.div`
    max-width: 1400px;
    padding-left: 30px;
    padding-right: 30px;

    h2 {
        font-weight: 300;
    }
    p {
        margin: 0 0 28px;
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        padding-left: 60px;
        padding-right: 60px;
    }
`;

const CardsWrapper = styled.div`
    .swiper-slide {
        margin-top: 8px;
        margin-bottom: 40px;
        width: 260px;
    }
    .swiper-pagination-bullet-active {
        background: ${colors.primary};
    }
`;

/* const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    a {
        font-size: 13px;
        font-weight: 500;
        padding-left: 0;
        padding-right: 0;
        width: 48%;
    }

    @media (min-width: ${breakpoints.tabletMin}) {
        display: block;

        a {
            padding-left: 25px;
            padding-right: 25px;
            width: auto;

            &:first-child {
                margin-right: 15px;
            }
        }
    }
`;
 */
export default TipsCarousel;
